import React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import sprite from '../assets/img/svg/svg-sprite.svg'
import { useAppDispatch, useAppSelector } from '../store'
import { slice as ordersSlice } from '../store/orders'
import { getDateString, getTimeString, parseEatPlace, translateToStyleCorner, translateToStyleOrder } from '../utils'

import Loader from './Loader'
import MarketSelect from './MarketSelect'
import OutletsList from './OutletsList'
import Profile from './Profile'

const Market: React.FC = () => {
  // --- EXTERNAL STATE --- //

  const history = useHistory()

  const dispatch = useAppDispatch()

  const market = useAppSelector((state) => state.market)
  const orders = useAppSelector((state) => state.orders)

  const selectedMarket = market.selected ? market.list.find((item) => item.id === market.selected) ?? null : null

  // --- LOCAL STATE --- //

  const yandex_order = 'yandex_order'
  const ninja_order = 'ninja_order'

  const [showMarketSelect, setShowMarketSelect] = React.useState(!market.selected)

  const [showProfile, setShowProfile] = useState(false)
  const [showOutlets, setShowOutlets] = useState(false)

  const [currentTime, setCurrentTime] = useState(new Date())
  const timeOptions = { hour12: false }

  // --- CALLBACKS --- //

  const closeMarketSelect = () => {
    setShowMarketSelect(false)
  }

  const openMarketSelect = () => {
    setShowMarketSelect(true)
  }

  const openOutlets = () => {
    setShowOutlets(true)
  }

  const closeOutlets = () => {
    setShowOutlets(false)
  }

  const openProfile = () => {
    setShowProfile(true)
  }

  const closeProfile = () => {
    setShowProfile(false)
  }

  const goToOrder = (orderId: number) => () => {
    history.push(`/order/${orderId}`)
  }

  // --- EFFECTS --- //

  React.useEffect(() => {
    if (!selectedMarket) return
    dispatch(ordersSlice.actions.loadStart())
  }, [selectedMarket])

  React.useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000)

    return function cleanup() {
      clearInterval(timer)
    }
  })

  // --- RENDERING --- //

  return (
    <div className="page__container">
      <div className="page__content">
        {showMarketSelect ? <MarketSelect close={closeMarketSelect} /> : null}
        {showProfile ? <Profile close={closeProfile} /> : null}
        {showOutlets ? <OutletsList close={closeOutlets} /> : null}
        <div className="market">
          <div className="market__header">
            <div className="market__title">
              <div className="market__corners_list-icon" onClick={openOutlets}>
                <svg width="26" height="26">
                  <use href={sprite + '#icon-shop'} />
                </svg>
              </div>
              <div className="headerContainer">
                <span onClick={openMarketSelect}>{selectedMarket ? selectedMarket.title : 'Не выбрано'}</span>
                <svg onClick={openMarketSelect} width="14" height="8">
                  <use href={sprite + '#arrow-down'} />
                </svg>
              </div>
              <div className="user__profile" onClick={openProfile}>
                <svg width="24" height="24">
                  <use href={sprite + '#icon-login'} />
                </svg>
              </div>
            </div>

            <div className="market__status_string">
              <div className="market__date">
                {getDateString()} {currentTime.toLocaleTimeString('en-US', timeOptions)}
              </div>
              <div className="market__date"> {orders.list.length} заказов </div>
            </div>
          </div>
          <div className="market__items">
            {orders.status === 'fetching' ? (
              <Loader />
            ) : orders.status === 'error' ? (
              <div>Что-то пошло не так</div>
            ) : orders.status === 'success' ? (
              orders.list.map((order) => (
                <div
                  className={`market__item ${
                    order.eat_place === 'togo_yandex' || order.eat_place === 'togo_sber' ? yandex_order : ninja_order
                  }`}
                  key={order.id}
                  onClick={goToOrder(order.id)}
                >
                  <div className="market__col">
                    <div className="market__number">
                      {order.id}{' '}
                      {order.eat_place === 'market_corner' || order.eat_place === 'market_table' ? (
                        !order.distanceFromMarketToCustomer ? (
                          <span className="distance_marker_danger">ПРОВЕРИТЬ!!!</span>
                        ) : order.distanceFromMarketToCustomer < 0.2 ? (
                          <span className="distance_marker_norm">
                            ✅{/* ({order.distanceFromMarketToCustomer} км.) */}
                          </span>
                        ) : order.distanceFromMarketToCustomer >= 0.2 ? (
                          <span className="distance_marker_danger">
                            {' '}
                            ПРОВЕРИТЬ!!!
                            {/* ({order.distanceFromMarketToCustomer.toPrecision(5)} км) */}
                          </span>
                        ) : null
                      ) : null}
                    </div>
                    <div className="order__eat_place">
                      {parseEatPlace(order.eat_place, order.car_number, order.table)}
                    </div>
                    {order.eat_place === 'togo_yandex' || order.eat_place === 'togo_sber' ? (
                      <div className="order__eat_place">{order.eats_id}</div>
                    ) : null}
                    {/*                     
                    <div className="market__tel">
                      {order.name ? <span>{order.name} </span> : null}
                      {order.phone ? <span>{order.phone}</span> : null}
                    </div> */}
                    <div className="market__sum">{order.full_cost} ₽</div>
                  </div>

                  <div className="market__col market__col_right">
                    <div className="corner__list">
                      {order.order_outlets.map(({ id, title, status }) => (
                        <div className="market__row" key={id}>
                          <div className="market__name">{title}</div>
                          <div className={`market__status market__status_${status}`}>
                            {translateToStyleCorner(status)}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="order__status-time">
                      <div className={`order__status-small order__status-${order.ready_status}`}>
                        {translateToStyleOrder(order.ready_status)}
                      </div>
                      <div className="order__time">{getTimeString(order.created_at)}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Market
